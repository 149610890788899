@font-face {
  font-family: "Avenir-Regular";
  src: url("../../../fonts/Avenir-Regular/Avenir-Regular.woff") format("woff");
}

body {
  overflow-x: hidden;
  font-family: "Avenir-Regular";
}

.register_form {
  background: white;

  .main_div {
    max-width: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }

  .account-pages {
    .form_upper_text {
      h1 {
        font-weight: 700;
        font: normal normal 900 24px/40px "Avenir";
        letter-spacing: 0.43px;
        color: #17100d;
      }

      p {
        color: #575757;
      }
    }
  }

  .form-horizontal {
    .form-label {
      color: #575757;
      font-size: 14px;
      font-weight: 200;
      font: normal normal 300 14px/22px "Avenir";
      letter-spacing: -0.39px;
      color: #575757;
    }

    ::placeholder {
      color: #d2d3d4 !important;
      opacity: 1;
      /* Firefox */
    }

    .select-place {
      color: #d2d3d4 !important;
    }
  }

  .form_side_img.p-0 img {
    object-fit: cover;
  }

  .btn-block {
    width: 100%;
    background: linear-gradient(180deg, #b924b4, #8525b7) !important;
    border: 0;
    margin-top: 10px;
  }

  .link_text {
    color: #eca109 !important;
    text-decoration: underline !important;
  }

  .brand_logo img {
    padding: 20px 0px !important;
  }

  .tipo_label {
    position: relative;
  }

  .tipo_main_class {
    position: relative;
  }

  .tipo_main_class img {
    position: absolute;
    top: 36px;
    right: 16px;
  }

  .btn-primary:hover {
    color: #fff;
    background-color: linear-gradient(180deg, #b924b4, #8525b7) !important;
    border: none;
  }

  .register_form {
    background: white;
  }

  .btn-check:focus + .btn-primary,
  .btn-primary:focus {
    color: #fff;
    background-color: linear-gradient(180deg, #b924b4, #8525b7) !important;
    border-color: linear-gradient(180deg, #b924b4, #8525b7) !important;
    box-shadow: 0 0 0 0.15rem rgba(111, 132, 234, 0.5);
  }
}

@media (max-width: 1199px) {
  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media (max-width: 991px) {
  .register_form .form_side_img.p-0 img {
    height: auto !important;
    width: 100%;
    object-fit: cover;
  }
}

@media (max-width: 767px) {
  .register_form .form_side_img.p-0 img {
    height: auto !important;
    width: 100%;
    object-fit: cover;
  }
}

@media (max-width: 575px) {
  .register_form .form_side_img.p-0 img {
    height: auto !important;
    width: 100%;
    object-fit: cover;
  }
}

.tacbox {
  padding: 1em;
  margin: 2em;
  max-width: 800px;
}

#acceptCondition {
  height: 1.3em;
  width: 1.3em;
  vertical-align: middle;
  margin-right: 5px;
}
